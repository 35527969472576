<template>
  <div id="mian">
    <div class="header">
      <span
        @click="navClick('批量查询')"
        :class="crumbs == '批量查询' ? 'active' : ''"
        >批量查询</span
      >
      <span
        @click="navClick('订单查询')"
        :class="crumbs == '订单查询' ? 'active' : ''"
        >订单查询</span
      >
    </div>
    <!-- 批量查询 -->
    <div v-if="crumbs == '批量查询'">
      <!-- 上面表单框 -->
      <div class="con_from">
        <div class="lineP">
          <div class="item">
            <i>电子账簿名称</i>
            <el-input
              v-model="query.keyword"
              maxlength="128"
              suffix-icon="el-icon-search"
              placeholder="电子账簿名称/编号"
            ></el-input>
          </div>          
          <div class="item">
            <i>所属商户</i>
            <el-select v-model="query.mchId">
                <el-option label="全部" value=""></el-option>
                <el-option
                  v-for="(v, i) in mchDrop"
                  :key="i"
                  :label="v.mchName"
                  :value="v.mchId"
                >
                </el-option>
            </el-select>
          </div>
          <div class="item">
            <i>收款电子账簿账号</i>
            <el-input
              v-model="query.accountNo"
              maxlength="128"
              suffix-icon="el-icon-search"
              placeholder="收款电子账簿账号"
            ></el-input>
          </div> 
        </div>
        <div class="lineP">        
          <div class="item">
            <i>付款方账户名称</i>
            <el-input
              v-model="query.payBankWord"
              maxlength="128"
              suffix-icon="el-icon-search"
              placeholder="付款方账户名称/账号"
            ></el-input>
          </div>
          <div class="item">
            <i>交易类型</i>
            <el-select
              v-model="query.trxType"
            >
              <el-option label="全部" value=""></el-option>
              <el-option label="子户入金" value="1"></el-option>
              <el-option label="退汇入金" value="2"></el-option>
            </el-select>  
          </div>             
        </div>
        <div class="lineP">
          <div class="item itemTime">
            <i>入账时间</i>
            <el-date-picker
              v-model="query.startTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择时间"
            >
            </el-date-picker>
            <span style="padding-left: 10px; padding-right: 10px">-</span>
            <el-date-picker
              v-model="query.endTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择时间"
            >
            </el-date-picker>
          </div>
          <div class="itembtn">
            <el-button type="primary" class="searchBt" @click="getLists">查询</el-button>
            <el-button type="primary" class="outputBt" @click="outputShow()" v-auth="'ELECTRON:BOOK:DEPOSIT/EXPORT'">导出</el-button>
          </div>  
        </div>
      </div>
      <!-- 中间交易账目 -->
      <div class="jiaoyi">
          <div class="jiaoyi_box" style="margin-right: 16px">
            <p><i>入金总数（笔）</i></p>
            <p>
              <b>{{ payNum }}</b>
            </p>
          </div>
          <div class="jiaoyi_box">
            <p><i>入金金额（元）</i></p>
            <p>
              <b>{{ (payAmount / 100) | formatMoney }}</b>
            </p>
          </div>
      </div>    
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>入账时间</p>
            </td>
            <td>
              <p>电子账簿名称</p>
              <p>电子账簿编号</p>
            </td>
            <td>
              <p>所属商户</p>
            </td>
            <td>
              <p>银行流水号</p>
            </td>
            <td>
              <p>交易类型</p>
            </td>
            <td>
              <!-- <p>电子账簿名称</p> -->
              <p>收款电子账簿账号</p>
            </td>
            <td>
              <p>付款方账户名称</p>
              <p>付款方账号</p>
            </td>
            <td>
              <p>付款方银行名称</p>
              <p>付款方联行号</p>
            </td>
            <td>
              <p>入账金额</p>
            </td>
            <td>
              <p>交易附言</p>
            </td>
          </tr>
          <tr v-for="(v, i) in tabData" :key="i">
            <td>
              <p>{{ v.registryTime }}</p>
            </td>
            <td>
              <p>{{ v.allocMchName }}</p>
              <p>{{ v.allocMchId }}</p>              
            </td>
            <td>
              <p>{{ v.mchName }}</p>
            </td>
            <td>
              <p>{{ v.bankTrxNo }}</p>
            </td>
            <td>
            <p>{{ trxTypeCode[v.trxType] }}</p>
            </td>
            <td>
              <!-- <p>{{ v.accountName }}</p> -->
              <p>{{ v.accountNo }}</p>
            </td>
            <td>
              <p>{{ v.payBankAccountName }}</p>
              <p>{{ v.payBankAccountNo }}</p>
            </td>
            <td>
              <p>{{ v.payBankName }}</p>
              <p>{{ v.payBankBrnNo }}</p>
            </td>
            <td>
              <p>{{ (v.amount / 100) | formatMoney }}</p>
            </td>
            <td>
              <p>{{ v.remark }}</p>
            </td>
          </tr>
        </table>
      </div>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalNum"
      >
      </el-pagination>    
    </div>
    <!-- 按订单号查询 -->
    <div v-if="crumbs == '订单查询'">
      <!-- 上面表单框 -->
      <div class="con_from">
        <div class="lineP">
          <div class="item itemTwo">
            <i>银行流水号</i>
            <el-input
              v-model="queryOrder.bankTrxNo"
              maxlength="128"
              suffix-icon="el-icon-search"
              placeholder="搜索银行流水号"
              style="width: calc(100% - 170px - 20px);"
            ></el-input>   
          </div>
          <div class="itembtn">
            <el-button type="primary" class="searchBt" @click="getLists">查询</el-button>        
          </div>     
        </div>
      </div>   
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>入账时间</p>
            </td>
            <td>
              <p>电子账簿名称</p>
              <p>电子账簿编号</p>
            </td>
            <td>
              <p>所属商户</p>
            </td>
            <td>
              <p>银行流水号</p>
            </td>
            <td>
              <p>交易类型</p>
            </td>
            <td>
              <!-- <p>电子账簿名称</p> -->
              <p>收款电子账簿账号</p>
            </td>
            <td>
              <p>付款方账户名称</p>
              <p>付款方账号</p>
            </td>
            <td>
              <p>付款方银行名称</p>
              <p>付款方联行号</p>
            </td>
            <td>
              <p>入账金额</p>
            </td>
            <td>
              <p>交易附言</p>
            </td>
          </tr>
          <tr v-for="(v, i) in tabData" :key="i">
            <td>
              <p>{{ v.registryTime }}</p>
            </td>
            <td>
              <p>{{ v.allocMchName }}</p>
              <p>{{ v.allocMchId }}</p>              
            </td>
            <td>
              <p>{{ v.mchName }}</p>
            </td>
            <td>
              <p>{{ v.bankTrxNo }}</p>
            </td>
            <td>
            <p>{{ trxTypeCode[v.trxType] }}</p>
            </td>
            <td>
              <!-- <p>{{ v.accountName }}</p> -->
              <p>{{ v.accountNo }}</p>
            </td>
            <td>
              <p>{{ v.payBankAccountName }}</p>
              <p>{{ v.payBankAccountNo }}</p>
            </td>
            <td>
              <p>{{ v.payBankName }}</p>
              <p>{{ v.payBankBrnNo }}</p>
            </td>
            <td>
              <p>{{ (v.amount / 100) | formatMoney }}</p>
            </td>
            <td>
              <p>{{ v.remark }}</p>
            </td>
          </tr>
        </table>
      </div>    
    </div>    

    <el-dialog title="导出入金列表" :visible.sync="outPayShow" width="490px">
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title">商户名称</div>
          <el-select v-model="outputForm.mchId" placeholder="选择商户">
            <el-option
              v-for="v in mchDrop"
              :key="v.mchId"
              :label="v.mchName"
              :value="v.mchId"
            ></el-option>
          </el-select>
          <div class="overAccount_ul_title" v-show="selectTimeType == -1">
            <i>*</i>开始日期
          </div>
          <el-date-picker
            style="width: 200px; height: 36px"
            v-model="outputForm.startDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择时间"
            v-show="selectTimeType == -1"
            :disabled="selectTimeType != -1"
          >
          </el-date-picker>
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title"><i>*</i>交易日期</div>
          <el-select
            v-model="selectTimeType"
            @change="timeChange"
            placeholder="选择交易日期"
          >
            <el-option label="今天" value="1"></el-option>
            <el-option label="昨天" value="2"></el-option>
            <el-option label="近7天" value="7"></el-option>
            <el-option label="其它" value="-1"></el-option>
          </el-select>
          <div class="overAccount_ul_title" v-show="selectTimeType == -1">
            <i>*</i>结束日期
          </div>
          <el-date-picker
            style="width: 200px; height: 36px"
            :picker-options="endOption"
            v-model="outputForm.endDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择时间"
            v-show="selectTimeType == -1"
            :disabled="selectTimeType != -1"
          >
          </el-date-picker>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="outPayShow = false">取 消</el-button>
        <el-button class="addBt" @click="output">确 定</el-button>
      </span>
    </el-dialog>  

  </div>
</template>
<script>
import { formatDate } from "@/utils/common.js";
import { merchantsDrop } from "@/api/common.js";
import { getIncomeOrderList,getIncomeOrderListExport } from "@/api/trading/electron.js";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    let that = this;
    return {
      query: {
        pageNo: 1,
        pageSize: 10,
        mchId: "",
        startTime: formatDate(+new Date(), "yyyy-MM-dd 00:00:00"),
        endTime: formatDate(+new Date(), "yyyy-MM-dd 23:59:59"),
        // bankTrxNo:'',//  银行流水号
        trxType:'',// 交易类型 :1-子户入金, 2-退汇入金
        keyword:'',//  电子记账簿名称/编号
        payBankWord:'',//  付款方账户名称/编号

      },
      queryOrder:{
        bankTrxNo:'',//  银行流水号
      },      
      totalNum: 0,
      tabData: [],
      trxTypeCode: {
        1: "子户入金",
        2: "退汇入金",
      },
      payNum:"",//入金总数（笔）
      payAmount:"",//入金金额（元）
      outPayShow: false,
      outputForm: {
        startDate: "",
        endDate: "",
        mchId: "",
      },
      endOption: {
        disabledDate(date) {
          return (
            date.getTime() <
              new Date(that.outputForm.startDate) - 24 * 60 * 60 * 1000 ||
            date.getTime() >
              new Date(
                +new Date(that.outputForm.startDate) + 30 * 24 * 60 * 60 * 1000
              )
          );
        },
      },
      selectTimeType: "",
      crumbs: "批量查询",
      mchDrop: [], //商户列表
    };
  },
  computed: {
    ...mapState({
      electronDetail: "electron_deposit",
    }),
  },
  created() {
    if (this.electronDetail) {
      this.query = this.electronDetail.query;
      this.queryOrder = this.electronDetail.queryOrder;
    }
    this.getDrop();
    this.getLists(false);
  },
  methods: {
    ...mapMutations({
      setElectronRJ: "electron_setDeposit",
    }),
    //获取列表
    getLists(reset = true) {
      if (reset) {
        this.query.pageNo = 1;
      }
      this.query.startTime = this.query.startTime ? this.query.startTime : "";
      this.query.endTime = this.query.endTime ? this.query.endTime : "";
      this.query.keyword = this.query.keyword.trim();

      if(this.crumbs == "批量查询"){
        getIncomeOrderList(this.query).then((res) => {
          if (res) {
            this.tabData = res.resultData.order;
            this.payNum = res.resultData.payNum;
            this.payAmount = res.resultData.payAmount;
            if (this.tabData.length == 0) {
              this.$message({
                message: "暂无数据",
                duration: 1500,
              });
            }
            this.totalNum = res.resultData.totalNum;
          }
        });           
      }else{
        if(!this.queryOrder.bankTrxNo){
          this.$message.error("请输入银行流水号");
          return false;
        }
        getIncomeOrderList(this.queryOrder).then((res) => {
          if (res) {
            this.tabData = res.resultData.order;
            this.payNum = res.resultData.payNum;
            this.payAmount = res.resultData.payAmount;
            if (this.tabData.length == 0) {
              this.$message({
                message: "暂无数据",
                duration: 1500,
              });
            }
            this.totalNum = res.resultData.totalNum;
          }
        });
      }
    },
    // 获取下拉
    getDrop() {
      merchantsDrop().then((res) => {
        if (res) {
          this.mchDrop = res.resultData;
        }
      });
    },
    // 导出
    output() {
      if (!this.outputForm.startDate || !this.outputForm.endDate) {
        this.$message.error("导出时间段必选");
        return;
      }
      let data = this.outputForm;
      getIncomeOrderListExport(data).then((res) => {
        if (res) {
          let url = window.URL.createObjectURL(new Blob([res]));
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", "入金列表.csv");
          document.body.appendChild(link);
          link.click();
        }
      });
      this.outPayShow = false;
    },    
    outputShow() {
      this.outPayShow = true;
      this.outputForm = {
        startDate: "",
        endDate: "",
        mchId: "",
      };
      this.selectTimeType = "";
    }, 
    // 选择时间类型
    timeChange(value) {
      switch (value) {
        case "1":
          this.outputForm.startDate = formatDate(+new Date(), "yyyy-MM-dd");
          this.outputForm.endDate = formatDate(+new Date(), "yyyy-MM-dd");
          break;
        case "2":
          this.outputForm.startDate = formatDate(
            +new Date() - 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          this.outputForm.endDate = formatDate(
            +new Date() - 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          break;
        case "7":
          this.outputForm.startDate = formatDate(
            +new Date() - 6 * 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          this.outputForm.endDate = formatDate(+new Date(), "yyyy-MM-dd");
          break;
        case "-1":
          this.outputForm.startDate = "";
          this.outputForm.endDate = "";
          break;
      }
    },
    //菜单切换
    navClick(data) {
      this.crumbs = data;
      this.tabData = [];
      if (data == "批量查询") {
        this.getLists();
      } else {
        if (this.queryOrder.bankTrxNo) {
          this.getLists();
        }
      }
    },
    // 页面大小改变
    handleSizeChange(size) {
      this.query.pageSize = size;
      this.getLists();
    },
    handleCurrentChange(page) {
      this.query.pageNo = page;
      this.getLists(false);
    },
  },
  beforeDestroy() {
    let data = {
      query: this.query,
      queryOrder: this.queryOrder,
    };
    this.setElectronRJ(data);
  },
};
</script>
<style scoped>
.con_from .lineP .item{
  width: 31.5%;
  margin-left: 2.5%;
}
.con_from .lineP .item:first-child{
  margin-left: 0%;
}
.con_from .lineP .item i {
  width: 120px;
}
.con_from .lineP .itemTime {
  width: 52.78%;
}
.con_from .lineP .item .el-input , .con_from .lineP .item .el-select{
  width: calc(100% - 120px - 10px);
}
.con_from .lineP .itemTime .el-input{
  width: calc((100% - 120px - 24.67px - 10px) / 2);
}

.con_from .lineP .itemTwo {
    width: 38%;
}
.jiaoyi_box{
  width: 49%;
}
.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}

.my-el-dialog {
  display: flex;
  flex-direction: column;
}

.my-el-dialog-item {
  height: 36px;
  line-height: 36px;
}

.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: #333333;
  font-size: 12px;
  padding: 0;
}
.overAccount_ul_title i {
    color: #48b8b6;
}
</style>
